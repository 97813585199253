// BlockContentGrid

import * as React from 'react'
const blockContentGrid = ({ children }) => {
  return (
    <div className="block-content g-m-1-13 g-l-4-12 grid">
      {children}
    </div>
  )
}
export default blockContentGrid