// Use as a Generic section with Children

import * as React from 'react'
import SectionTitle from "../components/sectionTitle"
const BlockSection = ({ id, sectionTitle, children }) => {
  return (
    <section className="block block-section" id={id}>
    {sectionTitle ? <SectionTitle text={sectionTitle} /> : null}
    {children}
  </section>
  )
}
export default BlockSection