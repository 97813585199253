import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import BlockSection from "../components/blockSection"
import BlockContentGrid from "../components/blockContentGrid"

const GenericPage = () => {
  const pageTitle = "Freshly Commerce"

  return (
    <Layout pageTitle={pageTitle}>

    <Seo title={pageTitle} />

    <Hero
      heroText="Disclaimer"
      heroDesc="Last updated May 14, 2021"
    />
    
    <BlockSection sectionTitle="WEBSITE DISCLAIMER">
      <BlockContentGrid>
        <div className="g-m-1-13 g-l-1-10">
          
          <p className="default">The information provided by Freshly Commerce Inc. (“we,” “us” or “our”) on <a href="https://www.freshlycommerce.com/" target="_blank" rel="noreferrer">https://www.freshlycommerce.com/</a> (the “Site”) and our mobile application is for general informational purposes only. All information on the Site and our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site or our mobile application . UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION . YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.</p>
          
        </div>
      </BlockContentGrid>
    </BlockSection>

    <BlockSection sectionTitle="EXTERNAL LINKS DISCLAIMER">
      <BlockContentGrid>
        <div className="g-m-1-13 g-l-1-10">
          
          <p className="default">The Site and our mobile application may contain (or you may be sent through the Site or our mobile application ) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>

        </div>
      </BlockContentGrid>
    </BlockSection>

    <BlockSection sectionTitle="PROFESSIONAL DISCLAIMER">
      <BlockContentGrid>
        <div className="g-m-1-13 g-l-1-10">

          <p className="default">The Site cannot and does not contain food safety advice. The food safety information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of food safety advice. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THIS SITE OR OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.</p>
          
        </div>
      </BlockContentGrid>
    </BlockSection>

    <BlockSection sectionTitle="TESTIMONIALS DISCLAIMER">
      <BlockContentGrid>
        <div className="g-m-1-13 g-l-1-10">
          <p className="default">The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. YOUR INDIVIDUAL RESULTS MAY VARY.</p>

          <p className="default">The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public.</p>

          <p className="default">The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions. We are not affiliated with users who provide testimonials, and users are not paid or otherwise compensated for their testimonials.</p>
        </div>
      </BlockContentGrid>
    </BlockSection>
           
  </Layout>
  )
}
export default GenericPage
